@import '../../styles/customMediaQueries.css';

/* Can be given to Topbar when a modal is opened
 * so that the Topbar is not rendered on top of it.*/
.topbarBehindModal {
  z-index: initial;
}

.topbar {
  @media (--viewportMedium) {
    position: fixed;
    top: 0;
    left: 0;
    /* We need to raise Topbar above .container */
    z-index: var(--zIndexTopbar);
  }
}

.container {
  /* Layout */
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    position: relative;
    padding-top: var(--topbarHeightDesktop);
    min-height: calc(100vh - 178px);
  }
}

.topFiltersContainer {
  display: flex;
  align-items: center;
  padding: 24px 0;
  flex-wrap: wrap;

  @media (--viewportMedium) {
    padding: 24px;
  }

  @media (--viewportLarge) {
    padding: 24px 36px 0;
  }
}

/* Container for side and content */
.layoutWrapperContainer {
  composes: container;
  /* parent aka root is flexbox, this container takes all available space */
  flex-shrink: 0;

  /* This container uses flexbox layout */
  display: flex;
  flex-direction: column;
  width: 100%;

  /* When side nav is shown as a column, Topbar's box-shadow should be visible */
  z-index: calc(var(--zIndexTopbar) - 1);

  @media (--viewportMedium) {
    flex-direction: row;
    justify-content: center;
  }
}

.layoutWrapperFilterColumn {
  display: none;

  @media (--viewportMedium) {
    /* Layout */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 24px 0 0 24px;
  }
  @media (--viewportLarge) {
    /* Layout */
    padding: 40px 0 0 36px;

    flex-direction: column;
    justify-content: flex-start;
    border: none;
    box-shadow: none;
    background-color: transparent;
  }

  @media (min-width: 1440px) {
    padding: 40px 0 0 calc((100vw - 1368px) / 2);
  }
  @media (--viewportXLarge) {
    padding: 40px 0 0 calc((100vw - 1800px) / 2);
  }
}

.filterColumnContent {
  width: 210px;
  padding: 8px 24px 0 0;

  @media (--viewportMedium) {
    padding: 8px 24px 24px 0;
  }

  @media (--viewportLarge) {
    width: 240px;
    padding: 8px 36px 24px 0;
  }

  @media (min-width: 1440px) {
    padding: 8px 60px 24px 0;
  }
}
.filter:first-child {
  padding-top: 0;
}

.resetAllButton {
  composes: h5 from global;
  font-weight: var(--fontWeightMedium);
  color: var(--colorGrey300);

  /* Layout */
  display: inline;
  margin: 17px 0 0 0;
  padding: 0;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--colorGrey700);
  }

  @media (--viewportMedium) {
    margin: 24px 0 0 0;
    padding: 3px 0 5px 0;
  }
}

.layoutWrapperMain {
  /* Expand to the full remaining width of the viewport */
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0 24px 0 24px;

  @media (--viewportMedium) {
    /**
     * Calculate right padding to match Footer right border
     * and left padding together with border should be 60px.
     */
    padding: 24px 24px 0 24px;
    background-color: var(--colorWhite);
    border-left-width: 1px;
    border-left-style: solid;
    border-left-color: var(--colorGrey100);
  }

  @media (--viewportLarge) {
    /**
     * Calculate right padding to match Footer right border
     * and left padding together with border should be 60px.
     */
    padding: 40px 36px 0 36px;
  }

  @media (min-width: 1440px) {
    padding: 40px calc((100vw - 1368px) / 2) 0 59px;
  }
  @media (--viewportXLarge) {
    padding: 40px calc((100vw - 1800px) / 2) 0 59px;
  }
}

.searchResultContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 2px 0 0 0;

  @media (--viewportMedium) {
    padding: 0;
    flex-basis: 100%;
  }
}

.error {
  color: var(--colorFail);
}

.searchString {
  /* Search string should not break on white spaces - i.e. line-break should happen before. */
  white-space: nowrap;
}

.mainPanel {
  display: flex;
}

.mainPanelMapVariant {
  composes: mainPanel;
  padding: 14px 24px 0 24px;
  margin-bottom: 21px;

  @media (--viewportLarge) {
    padding: 14px 36px 0 36px;
  }
}

.mainPanelMapVariantShow {

}

.searchFiltersMobileMap {
  padding: 16px 24px;

  @media (--viewportMedium) {
    display: none;
  }
}

.searchFiltersMobileList {
  padding: 16px 0;

  @media (--viewportMedium) {
    display: none;
  }
}

.searchFiltersPanel {
  flex-grow: 1;
  display: flex;
  padding: 0 24px 24px 24px;

  @media (--viewportLarge) {
    padding: 0 36px 36px 36px;
  }
}

.listings {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.listingsForGridVariant {
  composes: listings;
}

.listingsForMapVariant {
  composes: listings;
  padding: 0 24px;

  @media (--viewportLarge) {
    padding: 0 36px;
  }
}

.newSearchInProgress {
  opacity: 0.1;
}

.searchListingsPanel {
  flex-grow: 1;
}

.mapPanel {
  @media (--viewportMedium) {
    /**
     * .container is using flexbox,
     * This specifies that mapPanel is taking 34% from the viewport width
     */
    flex-basis: 100%;

    /* Own layout settings */
    display: flex;
    height: 100%;
  }
}

.mapWrapper {
  /*display: none;*/ /* with display block js not seeing map*/
  opacity: 0;
  position: absolute;
  width: 100%;
  height: calc(100vh - 160px);

  z-index: -1;

  @media (--viewportSmall) {
    height: calc(100vh - 180px);
  }

  @media (--viewportLarge) {
    height: calc(100vh - 160px);
    margin-top: 24px;
  }
}

.mapWrapperShown {
  position: relative;
  display: block;
  opacity: 1;
  z-index: 1;
}

.mapFilters {
  position: absolute;


  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  padding: 7px 0;
  width: 100%;
  max-width: 150px;
  min-height: 40px;

  background: var(--colorWhite);
  border: 1px solid var(--marketplaceColor);
  border-radius: 5px;
  z-index: 1;
  font-size: 16px;

  @media (--viewportMedium) {
    flex-direction: row;
    left: 50%;
    transform: translateX(-50%);
    max-width: 300px;
    padding: 13px 0;
  }

  & [class*="MapToggle_toggle"],
  & [class*="SelectToggleFilterPopup_toggle"] {
    margin: 0 0;
    font-size: 16px;

    @media (--viewportMedium) {
      margin: 0 10px;
    }
  }

  & [class*="SelectToggleFilterPopup_toggleItem"] {
    margin: 0 10px 0 0;

    @media (--viewportMedium) {
      margin: 0;
    }
  }
}

.mapFiltersTop {
  top: 10px;
  bottom: auto;
  right: 10px;

  @media (--viewportMedium) {
    top: 10px;
    bottom: auto;
    right: auto;
  }
}

.mapFiltersBottom {
  top: 10px;
  bottom: auto;
  left: 60px;
  max-width: 100px;

  @media (--viewportMedium) {
    top: auto;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    max-width: 300px;
  }

  & > div {
    margin-bottom: 0;
  }
}

.map {
  width: 100%;
  height: 100%;
}

.categoriesFilter {
  flex: 0 0 100%;
  max-width: 100%;
  margin-bottom: 20px;
  padding: 0 24px;

  @media (--viewportMedium) {
    flex: 0 0 180px;
    max-width: 180px;
    margin-bottom: 0;
    padding: 0;
  }
}

.categoriesButton {
  background: url('./images/icon_categories.png') 92% 50% no-repeat;
  padding-right: 50px;
  color: var(--marketplaceColor);
  font-size: 14px;
  height: 44px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;

  @media (--viewportMedium) {
    width: 165px;
    padding: 0 50px 0 8px;
  }
}

.categoriesMenu {
  width: 100%;
}
