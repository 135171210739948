@import '../../../styles/customMediaQueries.css';

.root {
  position: relative;
  display: inline-block;
}

.fieldGroup {
  margin: 0;
  padding: 0;
  border: none;
}

.fieldGroupPlain {
  margin: 0;
  padding-left: 12px;
  padding-bottom: 6px;
  border: none;

  @media (--viewportMedium) {
    padding: 3px 0 29px 12px;
    padding: 4px 0 4px 12px;
  }
}

.fieldGroupPlainWithIcon {
  display: flex;
  align-items: center;
  padding: 0 0 0;
  
  @media (--viewportSmall) {
    padding: 21px 24px 0;
  }

  @media (--viewportLarge) {
    padding: 24px 36px 0;
  }
}

.list {
  margin: 0;
}

.item {
  padding: 2px 0;

  /* Fix broken multi-column layout in Chrome */
  page-break-inside: avoid;

  @media (--viewportMedium) {
    padding: 4px 0;
  }
}

.listWithIcons {

  /* max-width: calc(100vw - 48px); */
  max-width: 100vw;

  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  overflow-x: scroll;
  scroll-behavior: smooth;

  @media (--viewportMedium) {
    max-width: calc(100vw - 110px);
  }

  @media (--viewportLarge) {
    max-width: calc(100vw - 160px);
  }

  & .item {
    flex-shrink: 0;
    min-width: 45px;
    
    @media (--viewportLarge) {
      min-width: 80px;
    }

    & label {
      justify-content: center;
      margin-bottom: 0;
    }
  }


  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}

.listWithIcons::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}

.itemWithIcons {
  padding: 0;
  margin: 0 10px;
  text-align: center;
  cursor: pointer;
}

.arrowIconHolder {
  display: none;
  width: 30px;
  height: 30px;

  border-radius: 5px;
  border: 1px solid var(--colorGrey300);
  cursor: pointer;

  @media (--viewportMedium) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
