@import '../../../styles/customMediaQueries.css';

.root {
  padding-top: 24px;
  padding-bottom: 17px;
  border-bottom: 1px solid var(--colorGrey100);

  @media (--viewportMedium) {
    padding-top: 16px;
    padding-bottom: 0;
    border-bottom: 0;
  }
}

.rootWithIcons {
  display: flex;
  align-items: center;
  padding: 0;
  flex: 0 0 100%;
  max-width: 100%;


  @media (--viewportMedium) {
    flex: 0 0 calc(100% - 180px);
    max-width: calc(100% - 180px);
  }

}

.filterHeader {
  line-height: 24px;
}

.label,
.labelSelected {
  composes: marketplaceSmallFontStyles from global;
  font-weight: var(--fontWeightBold);

  /* Baseline adjustment for label text */
  margin-top: 0;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    margin-bottom: 8px;
  }
}

.label {
  color: inherit;
}

.labelSelected {
  color: var(--marketplaceColor);
}

.openSign {
  float: right;
}

.labelButton {
  /* Override button styles */
  width: 100%;
  outline: none;
  line-height: 24px;
  text-align: left;
  border: none;
  padding: 0;
  cursor: pointer;
  color: var(--colorGrey700);

  &:hover {
    color: var(--marketplaceColor);

    & * {
      stroke: var(--marketplaceColor);
    }
  }
}

.labelButtonContent {
  display: inline-block;
  width: 100%;
}

.optionsContainerOpen {
  height: auto;
  padding-bottom: 0;

  @media (--viewportMedium) {
    padding-bottom: 16px;
  }
}

.optionsContainerClosed {
  height: 0;
  overflow: hidden;
}

.twoColumns {
  @media (--viewportMedium) {
    column-count: 2;
  }
}

.optionBorder,
.optionBorderSelected {
  position: absolute;
  height: calc(100% - 12px);
  top: 4px;
  left: -24px;
  transition: width var(--transitionStyleButton);

  @media (--viewportMedium) {
    height: calc(100% - 8px);
    top: 8px;
  }
}

/* left animated "border" like hover element */
.optionBorder {
  width: 0;
  background-color: var(--colorBlack);
}

/* left static border for selected element */
.optionBorderSelected {
  width: 8px;
  background-color: var(--colorBlack);
}

.option {
  composes: h4 from global;
  font-weight: var(--fontWeightMedium);
  font-size: 16px;
  color: var(--colorGrey700);
  text-align: left;

  /* Layout */
  display: block;
  position: relative;
  margin: 0;
  padding: 4px 0 8px 12px;

  /* Override button styles */
  outline: none;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--colorBlack);
  }

  &:hover .menuItemBorder {
    width: 6px;
  }

  @media (--viewportMedium) {
    padding: 8px 0 0px 12px;
  }
}

.optionSelected {
  composes: option;
  color: var(--colorBlack);
}

.optionHighlight {
  color: var(--marketplaceColor);

  &:focus,
  &:hover {
    color: var(--marketplaceColorLight);
  }
}

.clearButton {
  composes: h5 from global;
  font-weight: var(--fontWeightMedium);
  color: var(--colorGrey300);

  display: block;
  margin: 12px 0 0 0;
  padding: 2px 0 4px 12px;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--colorGrey700);
  }

  @media (--viewportMedium) {
    margin: 16px 0 0 0;
    padding: 4px 0 4px 12px;
  }
}


.listWithIcons {

  /* max-width: calc(100vw - 48px); */
  max-width: 100vw;

  display: flex;
  justify-content: space-between;
  margin: 0 auto 12px;
  padding-bottom: 0;
  overflow-x: scroll;
  scroll-behavior: smooth;

  @media (--viewportMedium) {
    margin: 0 auto;
    max-width: calc(100vw - 110px);
  }

  @media (--viewportLarge) {
    max-width: calc(100vw - 295px);
    /* max-width: 100%; */
  }

  & .item {
    flex-shrink: 0;
    min-width: 45px;

    @media (--viewportLarge) {
      min-width: 80px;
    }

    & label {
      justify-content: center;
      margin-bottom: 0;
    }
  }


  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}

.listWithIcons::-webkit-scrollbar {
  display: none;  /* Safari and Chrome */
}

.itemWithIcons {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  margin: 0 8px;
  text-align: center;
  cursor: pointer;

  font-size: 12px;
  color: var(--colorGrey500);
  line-height: 1.2;
  -webkit-tap-highlight-color: transparent;

  &:focus {
    color: var(--colorGrey500);
     -webkit-tap-highlight-color: transparent;
  }

  & svg {
    fill: none;
  }
}

.itemWithIcons.optionHighlight {
  color: var(--colorBlack);
  -webkit-tap-highlight-color: transparent;

  & svg {
    filter: brightness(.1);
  }
}

.arrowIconHolder {
  display: none;
  width: 30px;
  height: 30px;

  border-radius: 5px;
  border: 1px solid var(--colorGrey300);
  cursor: pointer;

  @media (--viewportMedium) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
