@import '../../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  position: relative;
}

.searchOptions {

  @media (--viewportMedium) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

.searchResultSummary {
  margin: 0;
  width: calc(100% - 250px);
}

.loadingResults {
  composes: textSmall from global;

  /* Cover parent element's space*/
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;

  /* Layout */
  padding: 9px 0 0 0;
  margin: 0;
}

.resultsFound {
  white-space: nowrap;
}

.resultsNearBy {
  font-weight: 500;
  display: inline-block;
  margin: 10px 0;
}

.sortyByWrapper {
  display: inline-flex;
  align-items: center;
  margin-left: auto;
  flex-shrink: 0;
}

.sortyBy {
  font-weight: var(--fontWeightRegular);
  padding: 0 8px 0 0;
  display: none;

  @media (--viewportMedium) {
    display: inline-block;
  }
}

.resultsNearBy {
  font-weight: 500;
  display: inline-block;
  margin: 10px 0;
}

.searchFilters {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media (--viewportMedium) {
    justify-content: flex-end;
  }

  & > div {
    width: auto;
    order: 2;
    margin-top: 5px;
    padding: 7px 10px;

    border: 1px solid var(--colorGrey300);
    border-radius: 5px;

    @media (--viewportMedium) {
      padding: 12px 12px;
      width: 50%;
      margin-left: 10px;
    }

    @media (--viewportLarge) {
      width: 100%;
      order: unset;
      padding: 0;
      border: none;
      width: auto;
      margin-top: 0;
      margin-left: 0;
    }
  }
}

.searchFiltersHolder {

}
